// App.js
import { React, useState, useEffect } from "react";
import "./App.css";
import TaxonomyPage from "./taxonomyPage";
import { getAssetId } from "./widget";

function App() {
  const [uid, setUid] = useState(null);

  useEffect(() => {
    async function fetchData() {
      try {
        const assetId = await getAssetId();
        setUid(assetId);
      } catch (error) {
        console.error(error);
      }
    }
    fetchData();
  }, []);

  return (
    <div>
      <div>
        <TaxonomyPage assetuid={uid} />
      </div>
    </div>
  );
}

export default App;
