import ContentstackAppSDK from "@contentstack/app-sdk";

export async function getAssetId() {
  return new Promise(async (resolve, reject) => {
    try {
      const appSdk = await ContentstackAppSDK.init();
      const widgetconfig = appSdk.location.AssetSidebarWidget;
      const data = widgetconfig.getData();
      resolve(data.uid);
    } catch (error) {
      reject(error);
    }
  });
}
