// TaxonomyPage.js
import React, { useState, useEffect } from "react";
import "./Taxonomy.css";
import Collapsible from "react-collapsible";
import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";

function TaxonomyPage({ assetuid }) {
  const [taxonomyresponse, settaxonomyresponse] = useState(null);
  const [checked_terms, setCheckedTerms] = useState([]);
  const [entryuid, setentryuid] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const [data, setData] = useState([]);
  const [terms_uid, setTermsUid] = useState([]);
  const renderSubMenu = (subMenu, level = 1) => {
    return (
      <ul>
        {subMenu.map((subItem, index) => (
          <li key={index}>
            <input
              type="checkbox"
              id={`${index}`}
              value={subItem.uid}
              onChange={handleCheckboxChange}
              checked={checked_terms.includes(subItem.uid)}
            />
            <label htmlFor={`${index}`}>{subItem.name}</label>
          </li>
        ))}
      </ul>
    );
  };

  useEffect(() => {
    let is_subscription = false;
    fetch("https://chemaid.mutare.ai/api/get-taxonomy", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        taxonomy_name: "ehss_category",
        depth: "2",
      }),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        if (is_subscription === false) {
          setData(data);
          console.log(data);
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });

    if (assetuid) {
      fetch("https://chemaid.mutare.ai/api/get-taxonomy-entry", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          title: assetuid,
        }),
      })
        .then((response) => response.json())
        .then((data) => {
          if (is_subscription === false) {
            const termsuidArray = data[2].map((obj) => obj.term_uid);
            setCheckedTerms(termsuidArray);
            console.log(checked_terms);
            setentryuid(data[0]);
          }
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
    }
    return () => {
      is_subscription = true;
      console.log("unmounting");
      console.log(checked_terms);
      console.log(assetuid);
    };
  }, [assetuid]);

  const handleCheckboxChange = (e) => {
    const checked = e.target.checked;
    const uid = e.target.value;
    console.log(`I am event ${uid}`);
    if (checked) {
      // setTermsUid(prevUid => [...prevUid, uid, ...checked_terms]);
      if (!checked_terms.includes(uid)) {
        setCheckedTerms((prevTerms) => [...prevTerms, uid]);
        setTermsUid((prevUid) => [...prevUid, uid, ...checked_terms]);
      }
    } else {
      setCheckedTerms((prevUid) => prevUid.filter((id) => id !== uid));
      setTermsUid((prevUid) =>
        prevUid.filter((id) => id !== uid, ...checked_terms)
      );
    }
  };

  const handleApply = () => {
    console.log(`terms: ${terms_uid}`);
    console.log(`I am asset uid: ${assetuid}`);
    setIsLoading(true);
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        title: assetuid,
        taxonomy_name: "ehss_category",
        terms: terms_uid,
      }),
    };
    fetch("https://chemaid.mutare.ai/api/save-taxonomy-entry", requestOptions)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        console.log(data);
        settaxonomyresponse(data);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };

  const uncheckAllCheckboxes = () => {
    const checkboxes = document.querySelectorAll('input[type="checkbox"]');
    checkboxes.forEach((checkbox) => {
      checkbox.checked = false;
    });
  };

  const handleReset = () => {
    setCheckedTerms([]);
    uncheckAllCheckboxes();
    setIsLoading(false);
  };

  const handleUpdate = () => {
    console.log(`terms: ${terms_uid}`);
    console.log(`terms: ${terms_uid.length}`);
    console.log(`I am asset uid: ${assetuid}`);
    console.log(`I am entry Uid: ${entryuid}`);
    setIsLoading(true);
    if (terms_uid.length === 0) {
      const requestOptions = {
        method: "DELETE",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          entry_uid: entryuid,
        }),
      };
      fetch(
        "https://chemaid.mutare.ai/api/delete-taxonomy-entry",
        requestOptions
      )
        .then((response) => {
          if (!response.ok) {
            throw new Error("Network response was not ok");
          }
          return response.json();
        })
        .then((data) => {
          console.log(data);
          settaxonomyresponse(data);
          setIsLoading(false);
          console.log(taxonomyresponse);
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
    } else {
      const requestOptions = {
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          entry_uid: entryuid,
          title: assetuid,
          terms: terms_uid,
          taxonomy_name: "ehss_category",
        }),
      };
      fetch(
        "https://chemaid.mutare.ai/api/update-taxonomy-entry",
        requestOptions
      )
        .then((response) => {
          if (!response.ok) {
            throw new Error("Network response was not ok");
          }
          return response.json();
        })
        .then((data) => {
          console.log(data.json());
          settaxonomyresponse(data.json());
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
    }
    setIsLoading(false);
  };

  const handleClicks = () => {
    const anyCheckboxSelected = checked_terms.length > 0;
    if (entryuid.length === 0 && anyCheckboxSelected) {
      // apply-btn.disabled;
      handleApply();
      // apply-btn.enabled;
    } else if (anyCheckboxSelected) {
      handleUpdate();
    }
  };

  return (
    <div className="taxonomy-container">
      <div className="section1">
        <h1>Taxonomy</h1>
      </div>
      <div className="section2">
        {/* <div className="search-container">
          <input type="text" placeholder="Search..." />
          <button className="search-button">
            <i className="fas fa-search"></i>
          </button>
        </div> */}
        <div className="checkbox-container">
          {assetuid === null ? (
            <p>Asset not selected</p>
          ) : (
            console.log(assetuid)
          )}
          <ul>
            {data.map((item, index) => (
              <li key={index}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "flex-start",
                  }}
                >
                  <input
                    type="checkbox"
                    id={`${index}`}
                    value={item.uid}
                    checked={checked_terms.includes(item.uid)}
                    onChange={handleCheckboxChange}
                  />
                  <label htmlFor={`${index}`}>
                    <Collapsible
                      trigger={`${item.name}        ▼`}
                      triggerWhenOpen={`${item.name}         ▲`}
                    >
                      {item.child && renderSubMenu(item.child)}
                    </Collapsible>
                  </label>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>
      <div className="section3">
        <button className="cancel-button" onClick={handleReset}>
          Clear
        </button>
        {/* <button className="update-button" onClick={handleUpdate}>Update</button> */}
        <button
          id="apply-btn"
          className="apply-button"
          onClick={handleClicks}
          disabled={isLoading ? true : false}
          style={{
            backgroundColor: `${isLoading ? "#BEC8C8" : "#007BFF"}`,
            color: "#FFFFFF",
          }}
        >
          Apply
          {!isLoading ? (
            <></>
          ) : (
            <Spin
              indicator={
                <LoadingOutlined
                  style={{
                    fontSize: 18,
                    color: "#FFFFFF",
                    marginLeft: "10px",
                  }}
                  spin
                />
              }
            />
          )}
        </button>
      </div>
      <p>{taxonomyresponse}</p>
    </div>
  );
}

export default TaxonomyPage;
